<template>
	<div>
		<vue-turnstile 
			:site-key="siteKey"
			v-model="token" 
			size='normal'
			theme='auto'
			retry="never"
			:auto-reset=false
			@verified="onVerify"
			@error-callback="onFail"
			@expired-callback="onFail"
			@timeout-callback="onFail"
		/>
		<!-- <div>Token: {{ token }}</div> -->
	</div>
</template>

<script >
import VueTurnstile from '@gaviti/vue-turnstile';

export default {
	name: 'TurnStileWidget',
	props: {

	},
	components: {
		VueTurnstile,
	},
	created() {
	},
	data: () => ({
		token: null,
		siteKey: `${process.env.VUE_APP_CLOUDFLARE_SITE_KEY}`,
	}),
	methods: {
		// onRender() {
		// },
		onVerify(token) {
			this.$emit("setTurnstileToken", token);
		},
		onFail() {
			this.$emit("setTurnstileToken", null);
		},
	}
};
</script>
<template>
	<main class="page-container" id="page-live-bridge">
		<div class="live-component container">
			<div class="bridge-title">
				<time class="date">{{ brcInfo.BRC_SCHDG_BGNG_DT }}</time>
				<h2 class="title">{{ brcInfo.BRC_TIT }}</h2>
			</div>
			<div class="bridge-cover">
				<div class="live-home-container is-horizontal">
					<div class="live-home-inner">
						<div class="live-container">
							<header class="live-header">
								<div class="live-header-badge"></div>
								<h2 class="live-header-title">
									<div href="" class="title-link">
										<span class="title">{{ brcInfo.BRC_TIT }}</span>
									</div>
								</h2>
								<div class="live-header-content">
									<div class="live-seller"><span class="text">{{ getCtrNm(brcInfo) }}</span></div>
									<div class="live-watch">
										<i class="icon-watch"></i><span class="text">{{ addCommas(brcInfo.INQ_CNT + '') }}</span>
									</div>
								</div>
							</header>
							<div class="live-top-actions">
								<button type="button" v-if="brcInfo?.DP_YN === 'Y'" class="btn btn-icon btn-sound" @click="onClickMuteButtn">
									<i :class="isPlayerMute" id="soundIcon"></i>
								</button>
							</div>
							<div class="live-timer"></div>
							<div class="live-video-player">
								<div class="video-player-image"><img src="@/assets/img/@tmp/@tmp_live_view.png" alt="" @error="handleImgError" /></div>
								<template v-if="brcInfo?.DP_YN === 'Y' && brcInfo?.VOD_CDN_USE_YN === 'Y'">
									<iframe 
										id="myplayer" 
										:src="brcInfo.PLAYER_URL[0]" 
										:allowFullScreen="true" 
										webkitallowfullscreen="true" 
										mozallowfullscreen="true" 
										width="560" 
										height="315" 
										title="YouTube video player" 
										frameBorder="0" 
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
									/>
								</template>
								<template v-else-if="brcInfo?.DP_YN === 'Y' && brcInfo?.VOD_CDN_USE_YN === 'N'">
									<video 
										id="myplayer" 
										:src="brcInfo.PLAYER_URL[0]" 
										width="560" 
										height="315" 
										muted="true" 
										preload="auto" 
										autoplay="" 
									/>
								</template>
								<template v-else>
									<img id="myplayer" :src="brcInfo.BRC_ADNOTC_IMG_URL" width="560" height="315" loading="lazy" @error="handleImgError" />
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bridge-body">
				<div class="live-info-wrapper">
					<div class="bridge-seller">
						<div class="seller-link"><span class="seller-name">{{ getCtrNm(brcInfo) }}</span></div>
					</div>
					<div class="bridge-notice">
						<toggle-switch 
							id="sbptnSwitch" 
							:propModel="sbptnInfo" 
							:value="'Y'"
							class="color-orange" 
							label="알림받기" 
							@changefunc="changeFuncLiveSbptn()"
						/>
					</div>
					<div v-if="parseInt(brcInfo.BRC_STS_CD) >= 10" class="bridge-actions">
						<button type="button" class="button-default is-primary btn-share" @click="onClickSharePopupButton">
							<i class="icon-share"></i>
						</button>
					</div>
				</div>
				<section class="live-product">
					<header class="section-header"><h3 class="title">라이브 소개 상품</h3></header>
					<div class="grid-container product-container">
						<ul class="grid-list">
							<li v-for="(product, idx) in brcInfo.goods" :key="'gdsList_' + idx" class="grid-item">
								<article class="product-card">
									<div class="product-image">
										<a :href="product.GDS_PCOM_URL" class="image-link" target="_blank" rel=”noopener”>
											<img :src="product.GDS_IMG_URL" alt="" loading="lazy" @error="handleImgError" />
										</a>
										<div class="image-badge"></div>
									</div>
									<div class="product-info">
										<div class="title">
											<a :href="product.GDS_PCOM_URL" class="title-link" target="_blank" rel=”noopener”>
												<div class="text">{{ product.GDS_NM }}</div>
											</a>
										</div>
										<div class="price">
											<span class="price-current">{{ addThousandComma(product.GDS_PRC) }}<em>원</em></span>
										</div>
									</div>
								</article>
							</li>
						</ul>
					</div>
				</section>
				<!-- s: 댓글 -->
				<section class="comment-container">
					<header class="comment-header">
						<h3 class="header-title">댓글 {{ cmntList.length }}개</h3>
					</header>
					<div v-if="!!this.session.userId && cmntSmthPssFlag" class="comment-form-container">
						<!-- 댓글 등록 -->
						<turnstile-widget 
							v-if="isTurnstileVisible" 
							@setTurnstileToken="setTurnstileToken"
						/>
						<div class="comment-card">
							<div class="comment-avatar">
								<div class="avatar">
									<span class="avatar-short">{{ avatar }}</span>
								</div>
							</div>
							<div class="comment-write">
								<input 
									type="text" 
									class="form-comment form-control" 
									placeholder="공개 댓글 추가" 
									:name="cmntNmTyp.VOD_CMNT"
									:value="vodCmnt.cmntCn" 
									@change="onChangeCmntInpt($event)" 
									@keyup="onKeyUpCmntInput($event, null)" 
									@focus="onFocusCmntInpt" 
								/>
							</div>
							<button type="button" class="btn btn-primary btn-coupone comment-btn" @click="onClickVodCmntRegButtn()">
								<span class="text">등록</span>
							</button>
						</div>
						<!-- //댓글 등록 -->
					</div>
					<div class="comment-list-container">
						<ul class="comment-list">
							<li class="comment-item" v-for="(item, idx) in cmntList.slice(0, moreCmnt)" :key="idx">
								<div class="comment-card">
									<div class="comment-avatar">
										<div class="avatar">
											<span class="avatar-short">{{ item.lgnId?.slice(0, 3) }}</span>
										</div>
									</div>
									<div class="comment-content">
										<div class="comment-metadata">
											<span class="metadata-name">{{ item.lgnId }} </span>
											<span class="metadata-date">{{ transformCmntDate(item.regDt, item.mdfcnDt) }}</span>
										</div>
										<!-- 댓글 수정 -->
										<div v-if="mdfcnCmntId === item.vodCmntId" class="comment-card">
											<div class="comment-write">
												<input 
													type="text" 
													cols="1" 
													rows="1" 
													class="form-comment form-control" 
													placeholder="공개 댓글 추가" 
													:name="cmntNmTyp.MDFCN_CMNT"
													:value="mdfcnCmnt.cmntCn" 
													@change="onChangeCmntInpt($event)" 
													@keyup="onKeyUpCmntInput($event, null)" 
												/>
											</div>
											<button type="button" class="btn btn-primary btn-coupone comment-btn" @click="onClickMdfcnCmntMdfcnButtn()">
												<span class="text">수정</span>
											</button>
										</div>
										<!-- //댓글 수정 -->
										<div v-else class="comment-text">
											<p class="text">{{ item.cmntCn }}</p>
										</div>

										<div class="comment-actions">
											<div class="actions-rating">
												<button type="button" class="btn-rating" @click="onClickLkDsk(item, lkTmsCdVal)">
													<i class="icon-like"></i>
													<span class="text">{{ item.lkTms }}</span>
												</button>
												<button type="button" class="btn-rating" @click="onClickLkDsk(item, dskTmsCdVal)">
													<i class="icon-dislike"></i>
													<span class="text">{{ item.dskTms }}</span>
												</button>
											</div>
											<div class="actions-reply">
												<!-- // 캡챠 건수 제한 적용 -->
												<button v-if="cmntSmthPssFlag" type="button" class="btn-reply" @click.prevent="onClickReplyOption($event, item)">
													<span class="text">{{ item.vodCmntId === selectedVodCmntId && replyCmntRegVisible ? '취소' : '답글' }}</span>
												</button>

												<template>
													<button type="button" class="btn-reply" @click.prevent="onClickMdfcnOption(item)">
														<span class="text">{{ mdfcnCmntId === item.vodCmntId ? '취소' : '수정' }}</span>
													</button>
													<button type="button" class="btn-reply" @click="onClickDelOption($event, item)">
														<span class="text">삭제</span>
													</button>
												</template>
											</div>
										</div>
										<div :class="item.replyCmntListVisible && item.vodCmntId === selectedVodCmntId ? 'comment-reply is-visible' : 'comment-reply'">
											<button v-if="item.replyCnt > 0" class="btn-reply-view" @click.prevent="onClickReplyCmntBrows($event, item)">
												<i class="icon-arrow"></i>
												<span class="text"> 답글 {{ item.replyCnt }}개 보기 </span>
											</button>

											<ul :class="item.vodCmntId === selectedVodCmntId && (item.replyCmntListVisible || replyCmntRegVisible) ? 'comment-list' : 'comment-list noDisplay'">
												<!-- 답글 등록 -->
												<div v-if="item.vodCmntId === selectedVodCmntId && replyCmntRegVisible" class="comment-form-container">
													<div class="comment-card">
														<div class="comment-avatar">
															<div class="avatar">
																<span class="avatar-short">{{ avatar }}</span>
															</div>
														</div>
														<div class="comment-write">
															<input 
																type="text" 
																cols="1" 
																rows="1" 
																class="form-comment form-control" 
																placeholder="공개 댓글 추가" 
																:name="cmntNmTyp.REPLY_CMNT" 
																:value="replyCmnt.cmntCn" 
																@change="onChangeCmntInpt($event)" 
																@keyup="onKeyUpCmntInput($event, item)" 
																@focus="onFocusCmntInpt" 
															/>
														</div>
														<button type="button" class="btn btn-primary btn-coupone comment-btn" @click="onClickReplyCmntRegButtn($event, item)">
															<span class="text">등록</span>
														</button>
													</div>
												</div>
												<!-- 답글 등록 -->
												<div v-if="item.replyCmntListVisible && item.vodCmntId === selectedVodCmntId">												
													<li v-for="(replyCmnt, index) in item.replyCmntList " class="comment-item" :key="index">
														<div class="comment-card">
															<div class="comment-avatar">
																<div class="avatar">
																	<span class="avatar-short">{{ replyCmnt.lgnId.slice(0, 3) }}</span>
																</div>
															</div>
															<div class="comment-content">
																<div class="comment-metadata">
																	<span class="metadata-name">{{ replyCmnt.lgnId }} </span>
																	<span class="metadata-date">{{ transformCmntDate(replyCmnt.regDt, replyCmnt.mdfcnDt) }}</span>
																</div>
																<!-- 댓글 수정 -->
																<div v-if="mdfcnCmntId === replyCmnt.vodCmntId" class="comment-card">
																	<div class="comment-write">
																		<input 
																			type="text" 
																			cols="1" 
																			rows="1" 
																			class="form-comment form-control" 
																			placeholder="공개 댓글 추가" 
																			:name="cmntNmTyp.MDFCN_CMNT" 
																			:value="mdfcnCmnt.cmntCn" 
																			@change="onChangeCmntInpt($event)" 
																			@keyup="onKeyUpCmntInput($event, null)" 
																		/>
																	</div>
																	<button type="button" class="btn btn-primary btn-coupone comment-btn" @click="onClickMdfcnCmntMdfcnButtn()">
																		<span class="text">수정</span>
																	</button>
																</div>
																<!-- //댓글 수정 -->
																<div v-else class="comment-text">
																	<p class="text">{{ replyCmnt.cmntCn }}</p>
																</div>
																<div class="comment-actions">
																	<div class="actions-rating">
																		<button type="button" class="btn-rating" @click="onClickLkDsk(replyCmnt, lkTmsCdVal)">
																			<i class="icon-like"></i>
																			<span class="text">{{ replyCmnt.lkTms }}</span>
																		</button>
																		<button type="button" class="btn-rating" @click="onClickLkDsk(replyCmnt, dskTmsCdVal)">
																			<i class="icon-dislike"></i>
																			<span class="text">{{ replyCmnt.dskTms }}</span>
																		</button>
																	</div>

																	<div class="actions-reply">
																		<button type="button" class="btn-reply" @click="onClickMdfcnOption(replyCmnt)">
																			<span class="text">{{ mdfcnCmntId === replyCmnt.vodCmntId ? '취소' : '수정' }}</span>
																		</button>
																		<button type="button" class="btn-reply" @click="onClickDelOption($event, replyCmnt)">
																			<span class="text">삭제</span>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</li>
												</div>
											</ul>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<button 
							v-if="cmntList.length > moreCmntCrtr && moreCmnt == moreCmntCrtr" 
							type="button" 
							class="btn-comment" 
							@click.prevent="onClickDisplayMoreCmnt"
						>
							<span class="text">더보기</span>
							<i class="icon-arrow"></i>
						</button>
					</div>
				</section>
				<!-- e: 댓글 -->
			</div>
		</div>
		<!-- 공유 팝업 -->
		<live-share-pop v-if="sharePopupVisible" :brcInfo=brcInfo @close="onCloseSharePopupButton"></live-share-pop>
		<!-- //공유 팝업 -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { 
	ACT_GET_PRHB_WRD_LIST,

	ACT_GET_BRC_INFO, 
	ACT_GET_LIVE_SBPTN,
	ACT_SAVE_LIVE_SBPTN,

	ACT_GET_VOD_CMNT,
    ACT_GET_VOD_CMNT_REPLY,
    ACT_INSERT_VOD_CMNT,
    ACT_UPDATE_VOD_CMNT,
    ACT_UPSERT_VOD_CMNT_LK_DSK
	} from '@/store/_act_consts';
import { 
	MUT_SHOW_CONFIRM, 
	MUT_SHOW_ALERT,
	MUT_SET_RETURN_ROUTE,

	MUT_SET_AVATAR,
} from '@/store/_mut_consts';

import { getItems, getItem, getByte, sliceByByte } from '@/assets/js/utils';
import errorImg from '@/assets/img/common/error.png';

import ToggleSwitch from '@/components/common/ToggleSwitch';
import LiveSharePop from '@/components/live/LiveSharePop';
import TurnstileWidget from '@/components/common/TurnstileWidget.vue';

let _controller;

//
export default {
	name: 'LiveVodView',
	components: { 
		ToggleSwitch, 
		LiveSharePop,
		TurnstileWidget,
	},
	data: () => ({
		isPlayerMute: 'icon-noSound',
		brcInfo: {},
		prhbWrdList: [],
		
		sbptnTrgtTypCd: [],
		sbptnInfo: [],
		sharePopupVisible: false,

		turnstileToken: null,
		isTurnstileVisible: false,
		
		cmntList: [
			{
				// replyCmntList: [],
				// replyCmntListVisible: false,
				// orgReplyCmntListCnt: null,
			},
		],
		cmntSmthCnt: 0,
		moreCmnt: 2,

		selectedVodCmntId: null,
		vodCmnt: {},

		mdfcnCmntId: null,
		mdfcnCmnt: {},
		orgMdfcnCmnt: {},

		replyCmnt: {},
		replyCmntRegVisible: false,
	}),
	computed: {
		...mapGetters('auth', ['session']),
		...mapGetters('sbptn', ['moreCmntCrtr']),
		...mapGetters('vod', ['cmntNmTyp', 'cmntSmthPssLimit', 'avatar', 'lkTmsCdVal', 'dskTmsCdVal']),

		cmntSmthPssFlag() {
			return this.cmntSmthCnt <= this.cmntSmthPssLimit;
		},
	},
	watch: {
		session: {
			handler(newVal, oldVal) {
				if (!!newVal && !!newVal.userId) {
					this.getLiveSbptn();
					this.$store.commit(`vod/${MUT_SET_AVATAR}`, newVal.lgnId.slice(0, 3));
				}
			},
			immediate: true,
		},
		brcInfo: function(newVal, oldVal) {
			this.getVodCmnt();
		},
	},
	created() {
		const vgControllerScript = document.createElement('script');
		vgControllerScript.setAttribute('src', 'https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js');
		document.head.appendChild(vgControllerScript);
	},
	mounted() {
		this.getBrcInfo();
		this.getPrhbWrd();
	},
	methods: {
		// promise ========================================================================================
		async getBrcInfo() {
			await this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
						brcInfoNo: this.$route.params.brcInfoNo,
						brcTyp: 1,
					})
				.then((res) => {
					if ("00" === res.data.RESULT_CD) {
						this.brcInfo = res.data.VIEW;
					}
				})
				.then(() => {
					if (this.brcInfo.DP_YN === "Y" && this.brcInfo.VOD_CDN_USE_YN === "Y") { 
						this.initVgController();
					}
				})
				.catch((err) => {
					console.error(err)
				});
		},

		async getLiveSbptn() {
			const reqParam = {
				userId: this.session.userId,
				sbptnTrgtTypCd: "300",
			};
				
			await this.$store
				.dispatch(`sbptn/${ACT_GET_LIVE_SBPTN}`, reqParam)
				.then((res) => {
					const sbptnYn = getItem(res)?.useYn || "N";
					const _sbptnInfo = sbptnYn === "Y" ? [sbptnYn] : ["N"];
					this.sbptnInfo = _sbptnInfo;
				});
		},
		async changeFuncLiveSbptn(val) {
			const nofuncCallback = () => {
				document.getElementById("sbptnSwitch").checked = false;
			};
			this.verifyUserSession(nofuncCallback);

			let reqBody = {
				userId: this.session.userId,
			};

			// 삭제
			if (this.sbptnInfo[0] === "Y") {
				const param = {
					html: true,
					title: '알림을 해제하시겠습니까?',
					showCancelButton: true,
					yesfunc: () => {
						reqBody["useYn"] = "N";
						this.saveLiveSbptn(reqBody);
					},
					nofunc: () => {
						document.getElementById("sbptnSwitch").checked = true;
					}
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
			}
			// 신규 및 수정
			else {
				reqBody["useYn"] = "Y";
				this.saveLiveSbptn(reqBody);
			}
		},
		async saveLiveSbptn(reqBody) {
			await this.$store
				.dispatch(`sbptn/${ACT_SAVE_LIVE_SBPTN}`, reqBody)
				.then((res) => {
					if (res.result.success) {
						this.sbptnInfo = [reqBody.useYn];
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},

		async getPrhbWrd() {
			await this.$store
				.dispatch(`common/${ACT_GET_PRHB_WRD_LIST}`, {})
				.then((res) => {
					this.prhbWrdList = getItems(res).map(each => each.prhbWrd);
				})
				.catch((err) => {
					console.error(err)
				});
		},

		async getVodCmnt() {
			const reqParam = {
				brcInfoNo: this.brcInfo.BRC_INFO_NO,
			};
			await this.$store
				.dispatch(`vod/${ACT_GET_VOD_CMNT}`, reqParam)
				.then((res) => {
					const newCmntList = res.cmntList.map((cmnt) => {
						return {
							...cmnt,
							replyCmntList: [],
							replyCmntListVisible: (cmnt.vodCmntId === this.selectedVodCmntId) ? true : false,
							orgReplyCmntListCnt: cmnt.replyCnt,
						}
					});
					this.cmntList = newCmntList;
					this.cmntSmthCnt = parseInt(res.cmntSmthCnt);

					if (newCmntList.some(each => each.replyCmntListVisible)) this.getVodReplyCmnt(this.selectedVodCmntId);
				})
				.catch(console.log);
		},
		async getVodReplyCmnt(_orgVodCmntId) {
			const reqParam = {
				orgVodCmntId: _orgVodCmntId,
			};
			await this.$store
				.dispatch(`vod/${ACT_GET_VOD_CMNT_REPLY}`, reqParam)
				.then((res) => {
					this.cmntList = this.cmntList.map((cmnt) => {
						if (reqParam.orgVodCmntId === cmnt.vodCmntId) {
							const _replyCnt = res.items.length;
							return {
								...cmnt,
								replyCnt: _replyCnt,
								replyCmntList: (_replyCnt > 0) ? getItems(res) : [],
								replyCmntListVisible: true,
								orgReplyCmntListCnt: _replyCnt,
							};
						}
						return {
							...cmnt,
							replyCmntListVisible: false,
						};
					});
					this.resetMdfcnProperty();
				})
				.catch(console.log);
		},
		async insertVodCmnt(cmntObj) {
			if (this.verifyInpt(cmntObj, { cmntCn: "댓글내용" })) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '입력정보를 확인하세요.' });
			}

			const isIncluded = this.prhbWrdList.some((wrd) => cmntObj.cmntCn.includes(wrd));
			if (isIncluded) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '댓글에 금칙어가 포함되어 있습니다.' });
			}

			if (this.turnstileToken == null || this.turnstileToken.trim() === "") {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: "'사람입니다.' 체크박스를 선택해주세요." });
			}

			const reqBody = {
				vodCmntDomain: Object.assign({}, cmntObj),
				turnstileToken: this.turnstileToken,
			};

			await this.$store
				.dispatch(`vod/${ACT_INSERT_VOD_CMNT}`, reqBody)
				.then((res) => {
					if (res.result.success) {
						if (1 === parseInt(cmntObj.depthVal)) {
							this.getVodCmnt();
							this.selectedVodCmntId = null;
							this.vodCmnt = {};
						}
						else {
							this.getVodReplyCmnt(cmntObj.orgVodCmntId);
							this.replyCmnt = {};
							this.replyCmntRegVisible = false;			
						}
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},
		async updateVodCmnt(cmntObj) {
			if (this.orgMdfcnCmnt.cmntCn === cmntObj.cmntCn) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: "입력정보를 확인하세요.",
				});
			}

			if (this.verifyInpt(cmntObj, { cmntCn: "댓글내용" })) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '입력정보를 확인하세요.' });
			}

			const isIncluded = this.prhbWrdList.some((wrd) => cmntObj.cmntCn.includes(wrd));
			if (isIncluded) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '댓글에 금칙어가 포함되어 있습니다.' });
			}

			delete cmntObj.replyCmntList;
			delete cmntObj.replyCmntListVisible;
			delete cmntObj.orgReplyCmntListCnt;
			const reqBody = Object.assign({}, cmntObj);

			await this.$store
				.dispatch(`vod/${ACT_UPDATE_VOD_CMNT}`, reqBody)
				.then((res) => {
					if (res.result.success) {
						if (1 === parseInt(cmntObj.depthVal)) {
							this.getVodCmnt();
						}
						else {
							this.getVodReplyCmnt(cmntObj.orgVodCmntId);
						}
						this.resetMdfcnProperty();
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},
		async deleteVodCmnt(cmntObj) {
			await this.$store
				.dispatch(`vod/${ACT_UPDATE_VOD_CMNT}`, cmntObj)
				.then((res) => {
					if (res.result.success) {
						if (1 === parseInt(cmntObj.depthVal)) {
							this.getVodCmnt();
						}
						else {
							this.getVodReplyCmnt(cmntObj.orgVodCmntId);
						}
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},
		async upsertVodCmntLkDsk(lkDskObj) {
			const _depthVal = lkDskObj.depthVal; 			delete lkDskObj.depthVal;
			const _orgVodCmntId = lkDskObj.orgVodCmntId; 	delete lkDskObj.orgVodCmntId;

			await this.$store
				.dispatch(`vod/${ACT_UPSERT_VOD_CMNT_LK_DSK}`, lkDskObj)
				.then((res) => {
					if (res.result.success) {
						if (1 === parseInt(_depthVal)) {
							this.getVodCmnt();
						}
						else {
							this.getVodReplyCmnt(_orgVodCmntId);
						}
					}
					else {
						console.info(res.result.message);
					}
				})
				.catch((err) => console.log(err));
		},

		// html event ========================================================================================
		onClickMuteButtn(e) {
			if (this.brcInfo.VOD_CDN_USE_YN === 'Y') {
				_controller.mute();
				_controller.on('muted', (is_muted) =>{
					(is_muted) ? this.isPlayerMute = "icon-noSound" : this.isPlayerMute = "icon-sound";
				});
			}
			else {
				const myplayer = document.getElementById('myplayer');
				myplayer.muted = !myplayer.muted;
				(myplayer.muted) ? this.isPlayerMute = 'icon-noSound': this.isPlayerMute = 'icon-sound';
			}
		},

		onClickSharePopupButton() {
			this.changeSharePopupVisible(true);
		},
		onCloseSharePopupButton() {
			this.changeSharePopupVisible(false);
		},
		changeSharePopupVisible(param) {
			this.sharePopupVisible = param;
		},

		onChangeCmntInpt(e) {
			const cmntObj = e.target.name;
			const _cmntCn = e.target.value;
			const _maxByte = 500;
			let txtLn = getByte(_cmntCn);

			const setCmntObj = () => this.$set(this[cmntObj], "cmntCn", sliceByByte(_cmntCn, _maxByte));

			if (txtLn > _maxByte) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { 
					title: `댓글은 250자 이내로 입력가능합니다. ${txtLn}/${_maxByte/2}`,
					yesfunc: () => {
						setCmntObj();
					},
					rightNow: true,
				});
			}

			setCmntObj();
		},
		onFocusCmntInpt(e) {
			this.isTurnstileVisible = true;
		},
		onKeyUpCmntInput(e, orgCmntObj) {
			const keyCode = e.keyCode;
			const name = e.target.name;

			if (keyCode == 13) {
				if (name === this.cmntNmTyp.VOD_CMNT) return this.onClickVodCmntRegButtn();
				if (name === this.cmntNmTyp.MDFCN_CMNT) return this.onClickMdfcnCmntMdfcnButtn();
				if (name === this.cmntNmTyp.REPLY_CMNT) return this.onClickReplyCmntRegButtn(e, orgCmntObj);
			}
		},

		onClickVodCmntRegButtn() {
			const cmntObj = {
				brcInfoNo: this.brcInfo.BRC_INFO_NO,
				writerId: this.session.userId,
				cmntCn: this.vodCmnt.cmntCn,
				depthVal: 1,
				orgVodCmntId: null,
			};
			this.insertVodCmnt(cmntObj);
		},
		onClickMdfcnCmntMdfcnButtn() {
			const cmntObj = {
				...this.mdfcnCmnt,
				writerId: this.session.userId,
			};
			this.updateVodCmnt(cmntObj);
		},
		onClickReplyCmntRegButtn(e, orgCmntObj) {
			const cmntObj = {
				brcInfoNo: this.brcInfo.BRC_INFO_NO,
				writerId: this.session.userId,
				cmntCn: this.replyCmnt.cmntCn,
				depthVal: 2,
				orgVodCmntId: orgCmntObj.vodCmntId,
			};
			this.insertVodCmnt(cmntObj);
		},
		
		onClickReplyCmntBrows(e, orgCmntObj) {
			if (!orgCmntObj.replyCmntListVisible) {
				this.selectedVodCmntId = orgCmntObj.vodCmntId;
				if (orgCmntObj.replyCnt > 0) this.getVodReplyCmnt(orgCmntObj.vodCmntId);
			}
			else {
				this.selectedVodCmntId = null;
				this.replyCmnt = {};
				this.replyCmntRegVisible = false;

				this.cmntList = this.cmntList.map((cmnt) => {
					if (cmnt.vodCmntId === orgCmntObj.vodCmntId) {
						return {
							...cmnt,
							replyCmntListVisible: false,
						}
					}
					return cmnt;
				})
			}
		},
		onClickReplyOption(e, orgCmntObj) {
			if (!this.verifyUserSession()) return;
			/*  
				답글 누를시 -> 등록인풋, 댓글목록
				(답글)취소 누를시 -> 등록인풋만 사라짐

				답글목록 누를시 -> 댓글목록만
				답글목록 닫을시 -> 등록인풋, 댓글목록 사라짐
			*/
			this.replyCmnt = {};

			if (!this.replyCmntRegVisible) {
				this.selectedVodCmntId = orgCmntObj.vodCmntId;
				this.replyCmntRegVisible = true;
				if (orgCmntObj.replyCnt > 0) this.getVodReplyCmnt(orgCmntObj.vodCmntId);
			}
			else {
				// this.replyCmnt = {};
				this.replyCmntRegVisible = false;
			}
		},
		onClickMdfcnOption(cmntObj) {
			if (!this.verifyUserSession()) return;

			this.mdfcnCmnt = { ...cmntObj };
			this.orgMdfcnCmnt = { ...cmntObj };

			if (this.mdfcnCmntId === cmntObj.vodCmntId) {
				this.resetMdfcnProperty();
			} 
			else {
				this.mdfcnCmntId = cmntObj.vodCmntId;
			}
		},
		onClickDelOption(e, cmntObj) {
			if (!this.verifyUserSession()) return;

			const param = {
				header: false,
				html: true,
				title: '삭제하시겠습니까?',
				showCancelButton: true,
				confirmButtonText: '예',
				cancelButtonText: '아니오',
				yesfunc: () => {
					const _cmntObj = {
						vodCmntId: cmntObj.vodCmntId,
						depthVal: cmntObj.depthVal,
						orgVodCmntId: cmntObj.orgVodCmntId,
						writerId: this.session.userId,
						useYn: "N",
					};
					this.deleteVodCmnt(_cmntObj);
				},
			};

			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
		},

		onClickLkDsk(cmntObj, codeVal) {
			if (!this.verifyUserSession()) return;

			const lkDskObj = {
				brcInfoNo: this.brcInfo.BRC_INFO_NO,
				vodCmntId: cmntObj.vodCmntId,
				userId: this.session.userId,
				lkDskTypCd: codeVal,

				depthVal: cmntObj.depthVal,
				orgVodCmntId: cmntObj.orgVodCmntId,
			};
			this.upsertVodCmntLkDsk(lkDskObj);
		},

		onClickDisplayMoreCmnt(e) {
			this.moreCmnt = this.cmntList.length;
			e.currentTarget.class = 'btn-comment noDisplay';
		},

		// custome fn ========================================================================================
		initVgController() {
			try {
				console.log('initVgController Start');

				_controller = new VgControllerClient({
					target_window: document.getElementById('myplayer').contentWindow
				});	

				_controller.on('error', (err) => {
					console.log('error: ' + err);
				});
				
				_controller.on('ready', () => {
					console.log('vg controller ready');

					// 크롬정책상 영상 자동재생 전 음소거
					// _controller.set_control_visibility(false);	// 내장 Controller 숨기:false, 숨김해제:true
					_controller.set_volume(50); 	// 볼륨 크기
					_controller.mute();			// 음소거
					_controller.play();			// 영상 재생
				});

				_controller.on('muted', (is_muted) => {
					if (is_muted) {
						this.isPlayerMute = "icon-noSound"
						is_muted = false;
					} 
					else {
						this.isPlayerMute = "icon-sound";
						is_muted = true;
					}
				});
			}
			catch (err) {
				console.log(err);
			}
		},
		getCtrNm(brcInfo) {
			const suppId = brcInfo.SUPP_ID;
			const ctrId = brcInfo.CTR_ID;

			if (suppId !== null) return brcInfo.SUPP_NM;
			else if (ctrId !== null) return brcInfo.CTR_NM;
			else return "가치삽시다 TV";
		},

		handleImgError(e) {
			e.target.src = errorImg;
		},

		addCommas(x) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		addThousandComma(number) {
			if (typeof number === 'undefined' || number === null) {
				return 0;
			}
			const numberStr = number.toString();
			if (numberStr.length <= 3) {
				return numberStr;
			} 
			else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
				return numberStr;
			} 
			else {
				let commaNumberStr = '';
				let i;
				for (i = numberStr.length - 3; i > 0; i = i - 3) 
				{
					commaNumberStr = ',' + numberStr.substring(i, i + 3) + commaNumberStr;
				}
				
				if (i + 3 > 0) {
					commaNumberStr = numberStr.substring(0, i + 3) + commaNumberStr;
				}
				return commaNumberStr; //number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); IE11 및 사파리에서 정상 동작 안함
			}
		},

		setTurnstileToken(token) {
			this.turnstileToken = token;
		},
		verifyInpt(valObj, nmObj) {
			const valKeyList = Object.keys(valObj);
			const nmKeyList = Object.keys(nmObj);
			let nameList = [];

			nmKeyList.forEach((nmKey) => {
				if (valKeyList.length < 1) {
					nameList.push(nmObj[nmKey]);
				}
				else {
					valKeyList.forEach((valKey) => {
						if (valKey === nmKey) {
							let trgtVal = valObj[valKey];
							if (typeof trgtVal === "string") trgtVal = valObj[valKey].trim();
							if (trgtVal === null || typeof trgtVal === "undefined" || trgtVal === "") {
								nameList.push(nmKeyList[nmKey]);
							}
						}
					})
				}
			});
			return nameList.length > 0;
		},
		verifyUserSession(nofuncCallback) {
			if (!this.session || !this.session.userId) {
				const param = {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {
						if (!!nofuncCallback) nofuncCallback();
					},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}
			return true;
		},

		transformCmntDate(regDt, mdfcnDt) {
			const date = new Date();
			const regdate = new Date(regDt);
			const betweenTimeDay = Math.floor((date.getTime() - regdate.getTime()) / (1000 * 60 * 60 * 24)); 	// 일
			const betweenTimeHour = Math.floor((date.getTime() - regdate.getTime()) / (1000 * 60 * 60)); 		// 시
			const betweenTimeMin = Math.floor((date.getTime() - regdate.getTime()) / (1000 * 60)); 				// 분

			let cmntTime = '';

			if (betweenTimeDay > 0) {
				cmntTime = `${betweenTimeDay}일전`;
			} 
			else {
				if (betweenTimeHour > 0) {
					cmntTime = `${betweenTimeHour}시간전`;
				} 
				else {
					if (betweenTimeMin > 0) {
						cmntTime = `${betweenTimeMin}분전`;
					} 
					else {
						cmntTime = `지금`;
					}
				}
			}

			return cmntTime;
		},
		resetMdfcnProperty() {
			this.mdfcnCmntId = null;
			this.mdfcnCmnt = {};
			this.orgMdfcnCmnt = {};
		},
	},
};
</script>
